import * as React from 'react'
import isAbsoluteUrl from 'is-absolute-url'
import NextLink, {LinkProps as NextLinkProps} from 'next/link'

import {Button, ButtonProps} from '../button'
import type {ClickableProps} from '../clickable'
import {Clickable} from '../clickable'

type LinkProps = NextLinkProps &
  ClickableProps & {
    children?: React.ReactNode
  }

type ButtonLinkProps = JSX.IntrinsicElements['a'] &
  NextLinkProps &
  ButtonProps & {
    children?: React.ReactNode
  }

/**
 * Styled Next.js link that manages both internal and external urls
 */
const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(function Link(
  {
    href,
    as,
    replace,
    scroll,
    shallow,
    passHref = true,
    prefetch,
    locale,
    ...props
  },
  ref,
) {
  if (typeof href !== 'string' || !isAbsoluteUrl(href)) {
    return (
      <NextLink
        href={href}
        as={as}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        passHref={passHref}
        prefetch={prefetch}
        locale={locale}
      >
        <Clickable as="a" ref={ref} {...props} />
      </NextLink>
    )
  }
  return <Clickable as="a" ref={ref} href={href} {...props} />
})

/**
 * Styled Next.js link that manages both internal and external urls
 */
const ButtonLink = React.forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  function ButtonLink(
    {
      href,
      as,
      replace,
      scroll,
      shallow,
      passHref = true,
      prefetch,
      locale,
      ...props
    },
    ref,
  ) {
    if (typeof href !== 'string' || !isAbsoluteUrl(href)) {
      return (
        <NextLink
          href={href}
          as={as}
          replace={replace}
          scroll={scroll}
          shallow={shallow}
          passHref={passHref}
          prefetch={prefetch}
          locale={locale}
        >
          <Button as="a" ref={ref} {...props} />
        </NextLink>
      )
    }
    return <Button as="a" ref={ref} href={href} {...props} />
  },
)

export {ButtonLink, Link}
